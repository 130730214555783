<template>
  <div class="md:container">
    <div class="flex gap-3 items-center overflow-hidden">
      <p class="body-10-aa text-grey-900 w-full">
        &copy;{{ currentYear }}
        <template v-if="company === companies.SwissGmbH">
          77 Diamonds Swiss GmbH -
          <span> Talacker 41. 8001 Zürich. Schweiz. </span>
          <span> UID: CHE-483.881.027 </span>
        </template>
        <template v-else-if="company === companies.GmbH">
          77 Diamonds GmbH -
          <span> Schumannstraße 27. 60325 Frankfurt. Deutschland </span>
          <span> Telefon: <span class="text-nowrap">+49 (0) 69 9754 6177</span> </span>
          <span> Handelsregisternummer: HR B 115026 (Amtsgericht Frankfurt am Main) </span>
        </template>
        <template v-else>
          77 Diamonds Limited - Registered in England and Wales -
          <span>
            {{ $t('registered-office', 'Registered office') }}: 2nd Floor, 3 Hanover Square, London, W1S 1HD.
          </span>
          <span> {{ $t('company-number', 'Company number') }}: 05142579. </span>
          <span> {{ $t('phone-number', 'Phone number') }}: <span class="text-nowrap">+44 203 540 1477</span> </span>
        </template>
      </p>

      <!-- <div class="text-nowrap"> -->
      <a
        href="https://www.assayassured.co.uk/clients/assured/certificates/67bb87a6-7046-11e2-97e8-001999b0ee30.php"
        :title="$t('footer-view-assay-assured', 'View Assay Assured Certification')"
        rel="noopener"
        target="_blank"
        class="lg:hidden"
      >
        <span class="icon77 icon77-logo-assay-sm sm:hidden"></span>
        <span class="icon77 icon77-logo-assay hidden sm:block"></span>
      </a>

      <div v-if="isTestEnvironment" class="trustedsite-trustmark loaded"></div>
      <div v-else class="trustedsite-trustmark" data-type="301" data-width="150" data-height="60"></div>
      <!-- </div> -->
    </div>
  </div>
</template>

<script setup lang="ts">
// TODO: update it once https://77diamonds.atlassian.net/browse/WV-1688 is done ?
withDefaults(defineProps<{ company: string | null; isTestEnvironment: boolean }>(), {
  company: null,
  isTestEnvironment: false,
})

const currentYear = new Date().getFullYear()
// is companies some static/hardcoded value? or should we get it from somewhere?
// TODO: update it once https://77diamonds.atlassian.net/browse/WV-1688 is done ?
const companies = {
  SwissGmbH: 'SwissGmbH',
  GmbH: 'GmbH',
  UK: 'UK',
}
</script>

<style scoped>
/* Add your CSS styles here */
.trustedsite-trustmark {
  width: 150px;
  height: 62px;
  display: inline-block;
  background-image: url('https://cdn.ywxi.net/meter/77diamonds.com/301.svg?ts=1660013742492&l=pt-BR');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
}
</style>
