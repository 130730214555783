<template>
  <footer id="footer" ref="footerRef" class="border-t border-darkLighter-400" data-cy="footer">
    <transition name="expand">
      <giveaway-content v-if="showGiveaway" @close-giveaway="showGiveaway = false" class="mt-4 md:hidden" />
    </transition>
    <div class="xl:container mt-6 md:mt-9">
      <div class="md:flex gap-3">
        <div class="md:basis-2/4 lg:basis-1/4 md:order-1">
          <FooterContactColumn />
        </div>
        <div id="sub-group-items" class="md:basis-2/4 lg:basis-3/4">
          <FooterNavigationSection :navigation-columns="footerNavigationColumns" />
        </div>
      </div>
    </div>
    <hr />
    <FooterBottomSection />
    <div id="addToBagContainer"></div>
  </footer>
</template>

<script setup lang="ts">
import FooterNavigationSection from './FooterNavigationSection.vue'
import FooterContactColumn from './FooterContactColumn.vue'
import FooterBottomSection from './FooterBottomSection.vue'
import type { FooterNavigationColumn } from '~/components/layout/footer/types'
import { getAboutUsSection, getCustomerCare, getLegalAndPrivacySection } from '~/components/layout/footer/constants'
import { useLayoutStore } from '~/store/layoutStore'
import GiveawayContent from '~/components/layout/footer/GiveawayContent.vue'

const { showGiveaway } = storeToRefs(useLayoutStore())
const footerRef = ref(null)
const { t } = useI18n()
const localePath = useLocalePath()
const { showGiveawayButton } = storeToRefs(useLayoutStore())

const footerNavigationColumns = computed(
  () =>
    [
      getCustomerCare(t, localePath),
      getAboutUsSection(t, localePath),
      getLegalAndPrivacySection(t, localePath),
    ] as FooterNavigationColumn[],
)

useIntersectionObserver(footerRef, ([{ isIntersecting }], observerElement) => {
  showGiveawayButton.value = !isIntersecting
})
</script>

<style lang="scss">
.expand-enter-active,
.expand-leave-active {
  transition:
    max-height 0.4s ease,
    opacity 0.4s ease;
}

.expand-enter-from,
.expand-leave-to {
  max-height: 0;
  opacity: 0;
}

.expand-enter-to,
.expand-leave-from {
  max-height: 500px; /* Adjust based on content */
  opacity: 1;
}
</style>
