<script setup lang="ts">
import useAuthStore from '~/store/auth'
import useUserStore from '~/store/user'
import { AUTH_COOKIE_NAME } from '~/constants/cookies'
import { AUTH_PAGES_ROUTES, PRIVATE_PAGES_ROUTES } from '~/constants/routes'

const authCookie = useCookie(AUTH_COOKIE_NAME, {
  path: '/',
  domain: '77diamonds.com',
  sameSite: 'Lax',
  expires: new Date(0),
})

const userStore = useUserStore()
const authStore = useAuthStore()

const isAuthMenuVisible = ref(false)
const authMenuRef = ref<HTMLElement | null>(null)

const toggleAuthPopup = () => {
  isAuthMenuVisible.value = !isAuthMenuVisible.value
}

const handleLogout = () => {
  authStore.logout().then(() => {
    authCookie.value = null
    navigateTo(AUTH_PAGES_ROUTES.LOGIN)
  })
}

const closePopupOnOutsideClick = (event: MouseEvent) => {
  const clickedInMenuContainer = ['auth-header-icon', 'auth-header', 'auth-popup'].includes(event.target.id)

  if (!clickedInMenuContainer) {
    isAuthMenuVisible.value = false
  }
}

onMounted(() => {
  document.addEventListener('click', closePopupOnOutsideClick)
})

onBeforeUnmount(() => {
  document.removeEventListener('click', closePopupOnOutsideClick)
})
</script>

<template>
  <div v-if="userStore.isLoggedIn" id="auth-header" ref="authMenuRef" class="hidden lg:block">
    <i id="auth-header-icon" class="icon77 icon77-user cursor-pointer" @click="toggleAuthPopup" />
  </div>
  <NuxtLinkLocale v-else class="hidden lg:block" to="/login">
    <i id="auth-header-icon" class="icon77 icon77-user cursor-pointer" />
  </NuxtLinkLocale>
  <div
    v-if="userStore.isLoggedIn"
    id="auth-popup"
    :class="[
      'hidden md:flex absolute top-[59px] z-50 border border-[#e4e4e4] bg-white shadow-[0_4px_9px_-5px_rgba(68,68,68,1)] p-6 flex-col transition-all duration-400 ease-linear w-[260px] text-left items-start right-[32px]',
      isAuthMenuVisible ? 'opacity-100 visible' : 'opacity-0 invisible',
    ]"
  >
    <ul class="list-none m-0 p-0">
      <li class="text-[14px] tracking-[0] leading-[24px]">
        <NuxtLinkLocale
          :to="PRIVATE_PAGES_ROUTES.PROFILE"
          class="text-[#0d0900] no-underline bg-transparent hover:font-bold"
        >
          {{ $t('my-profile', 'My Profile') }}
        </NuxtLinkLocale>
      </li>
      <li class="text-[14px] tracking-[0] leading-[24px] mt-3">
        <NuxtLinkLocale
          :to="PRIVATE_PAGES_ROUTES.ORDERS"
          class="text-[#0d0900] no-underline bg-transparent hover:font-bold"
        >
          {{ $t('my-orders', 'My Orders') }}
        </NuxtLinkLocale>
      </li>
      <li class="text-[14px] tracking-[0] leading-[24px] mt-3">
        <NuxtLinkLocale
          :to="PRIVATE_PAGES_ROUTES.ADDRESS"
          class="text-[#0d0900] no-underline bg-transparent hover:font-bold"
        >
          {{ $t('my-addresses', 'My Addresses') }}
        </NuxtLinkLocale>
      </li>
    </ul>

    <hr class="border-0 border-t border-t-[#e4e4e4] w-full my-4 box-content h-0 overflow-visible" />

    <button
      type="button"
      class="text-[14px] tracking-[0] leading-[24px] underline text-[#0d0900] font-bold capitalize border-none bg-none p-0"
      @click="handleLogout"
    >
      Sign Out
    </button>
  </div>
</template>
