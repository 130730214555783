<template>
  <section
    class="NavigationItems"
    :class="{ visible: props.displayMobileNavigationMenu }"
    @touchstart="handleTouchStart"
    @touchmove="handleTouchMove"
    @touchend="handleTouchEnd"
  >
    <div v-if="isMobile" class="wrapper-overlay" @click="handleClose"></div>
    <nav
      id="nav"
      class="main-nav"
      :class="{ 'left-0': props.displayMobileNavigationMenu }"
      :style="getMobileNavigationStyles"
    >
      <HeaderCurrencySelection class="lg:hidden py-2" display-flag />

      <ul class="main-menu">
        <li v-for="menu in navMenu" :key="menu.title" :class="['main-menu-item', menu.class]">
          <NuxtLinkLocale
            class="nav-link menu-title cursor-pointer"
            :class="[menu.class, { 'has-childs': menu.subItems }]"
            active-class="active"
            :href="getMenuLink(menu)"
            @click="activateMenu(menu.title)"
          >
            {{ menu.title }}
          </NuxtLinkLocale>
          <ul
            v-if="menu.subItems"
            class="sub-menu"
            :class="['-' + menu.id, { active: shouldDisplayMenuItem(menu) }]"
            :style="shouldDisplayMenuItem(menu) ? getMobileNavigationStyles : {}"
          >
            <li v-if="isMobile" class="nav-link menu-back" @click.self="deactivateMenu()">
              {{ $t('back', 'back') }}
            </li>
            <li
              v-for="subGroup in menu.subItems"
              :key="subGroup.id"
              :class="[subGroup.class, { active: activeSubMenu === subGroup.title }]"
              @click.stop="toggleSubMenu(subGroup.title)"
            >
              <template v-if="subGroup.link">
                <NuxtLinkLocale
                  :href="getMenuLink(subGroup)"
                  :class="['nav-link', 'submenu-title', { 'has-childs': subGroup.subItems }]"
                  >{{ subGroup.title }}
                </NuxtLinkLocale>
              </template>
              <template v-else>
                <h4 :class="['nav-link', 'submenu-title ', { 'has-childs': subGroup.subItems }]">
                  {{ subGroup.title }}
                </h4>
              </template>
              <template v-if="subGroup.subItems">
                <NuxtLinkLocale
                  v-for="item in subGroup.subItems"
                  :key="item.title"
                  :href="item.link"
                  :class="['submenu-link no-underline', item.class]"
                >
                  <i v-if="item.icon" :class="[item.icon]"></i>
                  {{ item.title }}
                  <span v-if="item.isNew" class="newTag">{{ $t('new') }}</span>
                </NuxtLinkLocale>
              </template>
              <NuxtLinkLocale v-if="subGroup.highlight" :href="subGroup.link" class="menu-highlight no-underline"
                >{{ subGroup.highlight }}
              </NuxtLinkLocale>
            </li>
          </ul>
        </li>
      </ul>

      <div v-if="isMobile" class="_headerBottomArea bg-gray-100 -mx-6 p-6 mb-3">
        <NuxtLinkLocale
          v-if="userStore.isLoggedIn"
          :to="PRIVATE_PAGES_ROUTES.PROFILE"
          class="nav-link submenu-title mb-2 -mt-2"
        >
          <i class="icon77 icon77-user" />
          {{ $t('my-account', 'My Account') }}
        </NuxtLinkLocale>
        <NuxtLinkLocale v-else :to="AUTH_PAGES_ROUTES.LOGIN" class="nav-link submenu-title mb-2 -mt-2">
          <i class="icon77 icon77-user" />
          {{ $t('login-register', 'Sign In / Register') }}
        </NuxtLinkLocale>
        <NuxtLink
          v-if="userStore.isLoggedIn"
          :to="AUTH_PAGES_ROUTES.LOGOUT"
          class="logout-btn nav-link submenu-title mb-2 -mt-2"
        >
          <i class="icon77 icon77-log-out" />
          {{ $t('sign-out', 'Sign Out') }}
        </NuxtLink>
        <NuxtLinkLocale to="/shop-bag" title="Shopping Bag" class="nav-link submenu-title mb-2 -mt-2">
          <i class="icon77 icon77-nav_bag" />
          {{ $t('nav-my-bag', 'My Bag') }}
        </NuxtLinkLocale>
        <NuxtLinkLocale to="/showrooms" class="nav-link submenu-title mb-3">
          <i class="icon77 icon77-nav_location" />
          {{ $t('our-showrooms', 'Our Showrooms') }}
        </NuxtLinkLocale>

        <OnlineAppointmentsButton class="w-[100%]" @click="handleClose" />
      </div>

      <div v-if="isMobile" class="headerBottomArea">
        <NuxtLinkLocale to="/testimonials.html" class="nav-link submenu-title">
          {{ $t('reviews', 'Reviews') }}
        </NuxtLinkLocale>
        <NuxtLinkLocale to="/faq.html" class="nav-link submenu-title">
          {{ $t('faqs', 'FAQs') }}
        </NuxtLinkLocale>
        <NuxtLinkLocale to="/delivery-and-returns" class="nav-link submenu-title">
          {{ $t('delivery-return', 'Delivery & Returns') }}
        </NuxtLinkLocale>
      </div>
    </nav>
  </section>
</template>

<script setup>
import HeaderCurrencySelection from './header-currency-sellection'
import useUserStore from '~/store/user'
import { getNavigationMenu } from './utils.ts'
import { AUTH_PAGES_ROUTES, PRIVATE_PAGES_ROUTES } from '~/constants/routes'

const props = defineProps({
  displayMobileNavigationMenu: {
    type: Boolean,
    default: false,
  },
})

const { t } = useI18n()
const localePath = useLocalePath()
const route = useRoute()
const { translateShopAspRouteToFriendlyUrl } = useAspRoutes()
const viewport = useViewport()

const userStore = useUserStore()

const emits = defineEmits(['close-mobile-menu'])
const activeMenu = ref('') // Store active menu state
const activeSubMenu = ref('') // Store active submenu state
const bodyRef = ref(null)

const navMenu = computed(() => {
  return getNavigationMenu({
    i18nTranslation: t,
    localePath,
    translateShopRoute: translateShopAspRouteToFriendlyUrl,
  })
})

const isMobile = computed(() => viewport.isLessThan('md'))

const activateMenu = (menuTitle) => {
  activeMenu.value = menuTitle
}

const deactivateMenu = () => {
  activeMenu.value = ''
}

const toggleSubMenu = (subMenuTitle) => {
  activeSubMenu.value = activeSubMenu.value === subMenuTitle ? '' : subMenuTitle
}

const shouldDisplayMenuItem = (menu) => {
  return isMobile ? menu.subItems && activeMenu.value === menu.title : menu.subItems
}

const getMenuLink = (menu) => {
  return isMobile.value && menu.subItems ? 'javascript:void(0);' : menu.link
}

const handleClose = () => {
  emits('close-mobile-menu')
}

// touch events
const menuCloseThreshold = -80 // Threshold to close the menu, for touch events
const isMoving = ref(false)
const touchStartX = ref(0)
const touchStartY = ref(0) // Track start Y position for vertical movement detection
const touchCurrentX = ref(0)
const menuPositionLeft = ref(0)

const getMobileNavigationStyles = computed(() => {
  if (!isMobile.value || menuPositionLeft.value === 0) return {}
  return {
    transition: isMoving.value ? 'none' : '', // Apply 'none' only while moving
    left: `${menuPositionLeft.value}px`,
  }
})

watch(
  () => route.fullPath,
  () => {
    nextTick(() => handleClose())
  },
)

watch(
  () => isMobile.value,
  () => {
    if (!isMobile.value) {
      handleClose()
    }
  },
)

const handleTouchStart = (event) => {
  isMoving.value = true
  touchStartX.value = event.touches[0].clientX
  touchStartY.value = event.touches[0].clientY // Initialize Y position
}

const handleTouchMove = (event) => {
  if (!isMoving.value) return

  touchCurrentX.value = event.touches[0].clientX
  const touchDeltaX = touchStartX.value - touchCurrentX.value
  const touchDeltaY = Math.abs(touchStartY.value - event.touches[0].clientY)

  // conditions copied from legacy
  // lock vertical scroll for horizontal movement and vice versa
  if (~touchStartY.value && (touchDeltaY < -5 || touchDeltaY > 5)) {
    isMoving.value = false
  } else if (touchStartY.value === -1 || touchDeltaX < -5 || touchDeltaX > 5) {
    touchStartY.value = -1

    // Set menu position, ensuring it does not move right beyond 0
    menuPositionLeft.value = Math.min(0, -touchDeltaX)
  }
}

const handleTouchEnd = () => {
  if (menuPositionLeft.value < menuCloseThreshold) {
    handleClose() // Close menu based on threshold
  }

  isMoving.value = false
  menuPositionLeft.value = 0 // Reset menu position
}

onBeforeMount(() => {
  bodyRef.value = document.body // Get the body element
})
</script>

<style scoped lang="scss">
.main-nav {
  font-family: 'Plain Light', sans-serif;
}

.main-nav a {
  color: #0d0900;
}

.main-nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.main-nav .nav-link {
  @apply no-underline;
  display: block;
  color: #0d0900;
  font-size: 12px;
  letter-spacing: 0.6px;
  text-transform: uppercase;
}

.main-nav .icon77 {
  margin-right: 5px;
  margin-top: -3px;
}

.main-nav .menu-title,
.main-nav .submenu-title,
.main-nav .menu-back {
  display: block;
  font-family: inherit;
}

.main-nav .menu-title,
.main-nav .menu-back {
  font-size: 14px;
  line-height: 30px;
}

.main-nav .submenu-title {
  font-size: 12px;
  line-height: 35px;
  margin-bottom: 0;
}

.main-nav .submenu-link {
  display: block;
  line-height: 36px;
  font-size: 14px;
  text-transform: capitalize;
}

.main-nav .submenu-link.link-highlight {
  background-color: #f3ece5;
  padding: 0 16px;
  font-size: 12px;
}

.main-nav .small-submenu-link .submenu-link {
  line-height: 32px;
}

.main-nav .menu-highlight {
  position: relative;
  display: block;
  background-color: #f3ece5;
  padding: 16px 46px 16px 16px;
  color: #0d0900;
  /*font-family: Plain;*/
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 24px;
}

.main-nav .menu-highlight:after {
  content: '';
  position: absolute;
  transform: rotate(-45deg);
  border: solid;
  border-width: 0px 1px 1px 0px;
  height: 8px;
  width: 8px;
  top: 50%;
  margin-top: -6px;
  right: 20px;
}

.main-nav .newTag {
  color: #fff;
  font-size: 7px;
  background-color: #0d0900;
  padding: 4px 6px 2px;
  margin-left: 4px;
  vertical-align: middle;
}

.main-nav .metal-icon {
  width: 40px;
  height: 20px;
  display: inline-block;
  margin-right: 8px;
  vertical-align: middle;
}

@media (min-width: 1024px) {
  .main-nav .metal-icon {
    margin-right: 12px;
    width: 20px;
  }
}

.main-nav .metal-icon.rose-gold {
  background-color: #f5c8a9;
}

.main-nav .metal-icon.yellow-gold {
  background-color: #e9d590;
}

.main-nav .metal-icon.white-gold {
  background-color: #efefef;
}

.main-nav .metal-icon.platinum {
  background-color: #c8c8c8;
}

.main-nav .metal-icon.palladium {
  background-color: #e7eaeb;
}

@media (min-width: 1024px) {
  body.stickyHeader .main-nav {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    background-color: white;
    width: 100%;
    height: 65px;
    border-bottom: 1px solid #ccc;
  }
}

a.header-phone-icon {
  font-family: 'Plain Light', sans-serif;
  color: #0d0900;
  font-size: 12px;
  letter-spacing: 0.4px;
}

.bookAppointment {
  cursor: pointer;
}

@media (max-width: 1024px) {
  .bookAppointment .icon77 {
    display: inline-block;
    margin-right: 12px;
  }
}

@media (max-width: 767px) {
  .bookAppointment .icon77 {
    margin-right: 0px;
  }
}

.bookAppointment .bookAppointment-title {
  margin: auto 10px;
  font-family: 'Plain Light', sans-serif;
  color: #0d0900;
  font-size: 13px;
  line-height: initial;
  letter-spacing: 0px;
}

@media (max-width: 1023.98px) {
  .NavigationItems {
    position: fixed;
    z-index: 1500;
    width: 100%;
    height: 100%;
    top: 0;
    visibility: hidden;
    transition: visibility 0.4s;
  }
  //body.menu .NavigationItems {
  //  visibility: visible;
  //}
  .wrapper-overlay {
    transition: background-color 0.4s;
    background-color: rgba(0, 0, 0, 0.3);
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    top: 0;
  }
  //body.menu .wrapper-overlay {
  //  background-color: rgba(0, 0, 0, 0.3);
  //  width: 100%;
  //}
  .main-nav,
  ul.sub-menu {
    height: 100%;
    overflow: auto;
    left: -320px;
    width: 85%;
    max-width: 320px;
    background-color: #fff;
    transition: left 0.5s ease;
    z-index: 10002;
    padding: 0 24px 24px;
  }
}

@media (max-width: 1023.98px) and (min-width: 480px) {
  .main-nav,
  ul.sub-menu {
    max-width: 400px;
    left: -400px;
  }
}

@media (max-width: 1023.98px) {
  .main-nav {
    position: absolute;
  }
  //body.menu .main-nav {
  //  left: 0;
  //}
  .main-nav .menu-title,
  .main-nav .menu-back {
    line-height: 42px;
  }
  .sub-menu {
    position: fixed;
    top: 0;
  }
  .sub-menu.active {
    left: 0;
  }
  .main-nav .menu-title.has-childs:before {
    content: '';
    float: right;
    transform: rotate(-45deg);
    border: solid;
    border-width: 0px 1px 1px 0px;
    height: 8px;
    width: 8px;
    margin: 17px 15px 0px 5px;
  }
  .main-nav li {
    padding: 8px 0;
    border-top: 1px solid #e4e4e4;
  }
  .main-nav li.active .submenu-title.has-childs:after {
    border-width: 1px 0px 0px 1px;
    margin-top: 15px;
  }
  .main-nav li.active .submenu-link {
    height: 36px;
  }
  .main-nav li.active .submenu-link:last-child {
    height: 41px;
  }
  .main-nav li.active .submenu-link.link-highlight {
    height: 26px;
  }
  .main-nav li.active .submenu-link.link-highlight:first-of-type {
    height: 36px;
  }
  .main-nav li.active .submenu-link.link-highlight:last-of-type {
    height: 36px;
  }
  .main-nav li.-double .submenu-link:nth-child(2n) {
    width: 50%;
    float: left;
  }
  .main-nav li.-double:after {
    content: '';
    clear: both;
    /* width: 210px; */
    display: block;
  }
  .main-nav .menu-back {
    border-top: 0;
  }
  .main-nav .menu-back:before {
    content: '';
    float: left;
    transform: rotate(135deg);
    border: solid;
    border-width: 0px 1px 1px 0px;
    height: 8px;
    width: 8px;
    margin: 17px 5px 0px 5px;
  }
  .main-nav .submenu-title.has-childs:after {
    content: '';
    float: right;
    transform: rotate(45deg);
    border: solid;
    border-width: 0px 1px 1px 0px;
    height: 8px;
    width: 8px;
    margin-right: 7px;
    margin-top: 10px;
  }
  .main-nav .submenu-link {
    height: 0;
    overflow: hidden;
    transition: height 0.2s;
  }
  .main-nav .submenu-link.link-highlight {
    line-height: 26px;
  }
  .main-nav .submenu-link.link-highlight:first-of-type {
    line-height: 46px;
  }
}

.main-menu {
  display: flex;
}

@media only screen and (max-width: 1023.8px) {
  .main-menu {
    flex-direction: column;
  }
}

@media only screen and (min-width: 1024px) {
  .main-menu {
    justify-content: center;
  }
  .main-menu .main-menu-item {
    display: inline-block;
    padding: 0 10px;
    line-height: 32px;
  }
}

@media only screen and (min-width: 1024px) and (min-width: 1440px) {
  .main-menu .main-menu-item {
    padding: 0 20px;
  }
}

@media only screen and (min-width: 1024px) {
  .main-menu .main-menu-item .menu-title {
    font-size: 12px;
    letter-spacing: 0.4px;
  }
  .main-menu .main-menu-item:hover .sub-menu {
    /*display: flex;*/
    visibility: visible;
    border: 1px solid #f2f2f2;
    border-width: 1px 0;
  }
  .main-menu .main-menu-item:hover .sub-menu {
    transition-delay: 0.3s;
  }
  .main-menu .main-menu-item:hover .sub-menu:after {
    content: '';
  }
  .main-menu .main-menu-item .menu-title {
    position: relative;
  }
  .main-menu .main-menu-item {
    &:hover .menu-title:after {
      content: '';
      border-bottom: 2px solid #ffc13b;
      /* margin-bottom: -1px; */
      z-index: 1000;
      /* position: relative; */
      width: 100%;
      left: 0;
      bottom: -1px;
      position: absolute;
    }
  }
  body.stickyHeader .main-menu .main-menu-item .menu-title {
    /*padding: 0 6px;*/
    line-height: 64px;
  }
}

@media only screen and (min-width: 1024px) and (min-width: 1440px) {
  body.stickyHeader .main-menu .main-menu-item .menu-title {
    padding: 0 12px;
  }
}

@media only screen and (min-width: 1024px) {
  .main-menu .sub-menu {
    position: absolute;
    display: flex;
    z-index: 200;
    left: 0;
    width: 100%;
    background: white;
    height: 300px;
    padding: 30px calc(50% - 500px) 40px;
    align-content: space-between;
    flex-flow: column wrap;
    /*transition: all 1s;*/
    visibility: hidden;
    /*@media only screen and (min-width: 1440px) {
              padding: 50px calc(50% - 600px);
          }*/
  }
  .main-menu .sub-menu li {
    max-width: 250px;
    margin-bottom: 25px;
  }
  .main-menu .sub-menu li.-double .submenu-link {
    width: 50%;
    float: left;
  }
  .main-menu .sub-menu li.-double:after {
    content: '';
    clear: both;
    /* width: 210px; */
    display: block;
  }
  .main-menu .sub-menu li.-small {
    max-width: 18%;
  }
  .main-menu .sub-menu li.-small .menu-highlight {
    font-size: 11px;
    line-height: 1.8;
    padding-right: 16px;
  }
  .main-menu .sub-menu li.-small .menu-highlight:after {
    content: none;
  }
  .main-menu .sub-menu .submenu-title {
    color: #666;
  }
  .main-menu .sub-menu.-engagement-rings {
    height: 500px;
  }
  .main-menu .sub-menu.-jewellery {
    height: 575px;
  }
  .main-menu .sub-menu.-diamonds {
    height: 460px;
  }
}

@media only screen and (min-width: 1024px) and (min-width: 1024px) {
  .main-menu .sub-menu:after {
    background-position: center;
    /*background-size: cover;*/
    background-repeat: no-repeat;
    order: 9999;
    width: 25%;
    height: 100%;
    image-rendering: pixelated;
    margin-top: 10px;
    /*margin-left: 5%;*/
  }
}

@media only screen and (min-width: 1024px) and (min-width: 1024px) and (min-resolution: 300dpi),
  only screen and (min-width: 1024px) and (min-width: 1024px) and (min-resolution: 2dppx) {
  .main-menu .sub-menu:after {
    background-size: cover;
  }
}

@media only screen and (min-width: 1024px) and (min-width: 1024px) {
  .main-menu .sub-menu.-engagement-rings:after {
    background-image: url('https://cdn.77diamonds.com/images/header/Categories/01_NAV_ER_x1.jpg');
  }
}

@media only screen and (min-width: 1024px) and (min-width: 1024px) and (min-resolution: 300dpi),
  only screen and (min-width: 1024px) and (min-width: 1024px) and (min-resolution: 2dppx) {
  .main-menu .sub-menu.-engagement-rings:after {
    background-image: url('https://cdn.77diamonds.com/images/header/Categories/01_NAV_ER_x2.jpg');
  }
}

@media only screen and (min-width: 1024px) and (min-width: 1024px) {
  .main-menu .sub-menu.-wedding-rings:after {
    background-image: url('https://cdn.77diamonds.com/images/header/Categories/03_NAV_WED_x1.jpg');
  }
}

@media only screen and (min-width: 1024px) and (min-width: 1024px) and (min-resolution: 300dpi),
  only screen and (min-width: 1024px) and (min-width: 1024px) and (min-resolution: 2dppx) {
  .main-menu .sub-menu.-wedding-rings:after {
    background-image: url('https://cdn.77diamonds.com/images/header/Categories/03_NAV_WED_x2.jpg');
  }
}

@media only screen and (min-width: 1024px) and (min-width: 1024px) {
  .main-menu .sub-menu.-eternity-rings:after {
    background-image: url('https://cdn.77diamonds.com/images/header/Categories/02_NAV_ET_x1.jpg');
  }
}

@media only screen and (min-width: 1024px) and (min-width: 1024px) and (min-resolution: 300dpi),
  only screen and (min-width: 1024px) and (min-width: 1024px) and (min-resolution: 2dppx) {
  .main-menu .sub-menu.-eternity-rings:after {
    background-image: url('https://cdn.77diamonds.com/images/header/Categories/02_NAV_ET_x2.jpg');
  }
}

@media only screen and (min-width: 1024px) and (min-width: 1024px) {
  .main-menu .sub-menu.-jewellery:after {
    background-image: url('https://cdn.77diamonds.com/images/header/Categories/04_NAV_DJ_x1.jpg');
  }
}

@media only screen and (min-width: 1024px) and (min-width: 1024px) and (min-resolution: 300dpi),
  only screen and (min-width: 1024px) and (min-width: 1024px) and (min-resolution: 2dppx) {
  .main-menu .sub-menu.-jewellery:after {
    background-image: url('https://cdn.77diamonds.com/images/header/Categories/04_NAV_DJ_x2.jpg');
  }
}

@media only screen and (min-width: 1024px) and (min-width: 1024px) {
  .main-menu .sub-menu.-diamonds:after {
    background-image: url('https://cdn.77diamonds.com/images/header/Categories/05_NAV_DIA_x1.jpg?v=2');
    background-position-y: top;
  }
}

@media only screen and (min-width: 1024px) and (min-width: 1024px) and (min-resolution: 300dpi),
  only screen and (min-width: 1024px) and (min-width: 1024px) and (min-resolution: 2dppx) {
  .main-menu .sub-menu.-diamonds:after {
    background-image: url('https://cdn.77diamonds.com/images/header/Categories/05_NAV_DIA_x2.jpg?v=2');
  }
}

@media only screen and (min-width: 1024px) and (min-width: 1024px) {
  .main-menu .sub-menu.-gemstones:after {
    background-image: url('https://cdn.77diamonds.com/images/header/Categories/06_NAV_GEM_x1.jpg');
  }
}

@media only screen and (min-width: 1024px) and (min-width: 1024px) and (min-resolution: 300dpi),
  only screen and (min-width: 1024px) and (min-width: 1024px) and (min-resolution: 2dppx) {
  .main-menu .sub-menu.-gemstones:after {
    background-image: url('https://cdn.77diamonds.com/images/header/Categories/06_NAV_GEM_x2.jpg');
  }
}

@media only screen and (min-width: 1024px) and (min-width: 1024px) {
  .main-menu .sub-menu.-education:after {
    background-image: url('https://cdn.77diamonds.com/images/header/Categories/07_NAV_EDU_x1.jpg');
  }
}

@media only screen and (min-width: 1024px) and (min-width: 1024px) and (min-device-pixel-ratio: 2) {
  .main-menu .sub-menu.-education:after {
    background-image: url('https://cdn.77diamonds.com/images/header/Categories/07_NAV_EDU_x2.jpg');
  }
}

@media only screen and (min-width: 1024px) and (min-width: 1440px) {
  .main-menu .sub-menu.-engagement-rings {
    padding-left: calc(50% - 600px);
    padding-right: calc(50% - 600px);
  }
}

@media only screen and (min-width: 1024px) {
  .main-menu .submenu-link.link-highlight {
    /*margin-left: -16px;*/
    line-height: 24px;
  }
  .main-menu .submenu-link.link-highlight:first-of-type {
    padding-top: 10px;
  }
  .main-menu .submenu-link.link-highlight:last-of-type {
    padding-bottom: 10px;
  }
}
</style>
