<template>
  <form data-di-form-track data-di-form-id="" novalidate class="" data-cy="fourth-step" @submit.prevent="submit">
    <button type="button" class="mb-3" @click="$emit('prevStep')">
      <i class="icon77 icon77-chevron -left"></i>
      <span class="heading-5">
        {{
          CurrentShowroom.IsOpen
            ? $t('book-an-appointment', 'Book an Appointment')
            : $t(
                'coming-soon-in-showroom-title',
                { showroomName: CurrentShowroom.City },
                'Coming Soon in {showroomName}',
              )
        }}
      </span>
    </button>

    <p v-if="!CurrentShowroom.IsOpen" class="body-14-aa">
      {{
        $t(
          'appoint-form-virtual-showroom-new',
          { showroomName: CurrentShowroom.City },
          'Send us a request to let us know you’re interested in meeting us in {showroomName}. A jewellery specialist will be in touch soon with further information.',
        )
      }}
    </p>

    <div class="apptFormTitle my-4">
      <div class="float-right">
        <span class="body-12-aa">* {{ $t('required-field', 'Required field') }}</span>
      </div>
      <h4 class="body-14-aa-cap">
        {{ $t('appoint-form-contact-title', 'Your Contact Details:') }}
      </h4>
    </div>

    <ui-form-group
      v-model="$v.UserData.FirstName"
      :placeholder="$t('first-name', 'First Name')"
      data-cy="first-name"
      class="mb-3"
    />

    <ui-form-group v-model="$v.UserData.LastName" :placeholder="$t('last-name')" data-cy="surname" class="mb-3" />

    <ui-form-group v-model="$v.UserData.Email" :placeholder="$t('email-address')" data-cy="email" class="mb-3" />

    <div v-if="CurrentShowroom.IsOpen" class="grid77 gap-0 mb-6 border items-center">
      <div class="col-span-3 mx-2 border-r border-r-grey-500 pr-4">
        <select v-model="UserData.PhoneCode" class="w-full body-14-aa" data-cy="phone-country">
          <template v-for="pc in phoneCodes">
            <option v-if="pc.Number" :value="pc.Number">+{{ pc.Number }}</option>
            <option v-else disabled>{{ pc.Name }}</option>
          </template>
        </select>
      </div>
      <div class="col-span-9">
        <ui-form-group v-model="$v.UserData.PhoneNumber" :placeholder="$t('phone-number')" data-cy="phone-number" />
      </div>
    </div>

    <div id="gdprCheckboxHolderFooter" class="gdprCheckboxHolder fieldHolder _form-group mb-6">
      <input
        id="gdprCheckboxFooter"
        v-model="gdprCheckboxFooter"
        type="checkbox"
        data-ga4-label="Consent Checkbox"
        data-ga4-pii
        class="required hidden"
        :data-empty-error-message="
          $t('gdpr-checkbox-notselected-error', 'In order to proceed you need to agree with the new privacy policy.')
        "
      />
      <label for="gdprCheckboxFooter" class="body-14-aa" data-cy="checkbox">
        <span
          v-html="
            $t(
              'apt-forms-tos',
              {
                link_privacy_policy: localePath('/privacy-77diamonds.html'),
                link_terms: localePath('/terms-conditions'),
              },
              'By submitting your details, you consent to receive emails from 77 Diamonds. You are free to unsubscribe at any time. For further information, please read our <a href={link_privacy_policy}>Privacy Policy</a> and our <a href={link_terms}>Terms and Conditions</a>',
            ).replace('Terms and Conditions', 'Terms & Conditions')
          "
        >
        </span>
      </label>

      <div class="_text-danger">
        <p v-if="gdprCheckboxFooter === false">
          {{
            $t('gdpr-checkbox-notselected-error', 'In order to proceed you need to agree with the new privacy policy.')
          }}
        </p>
      </div>
    </div>

    <button type="submit" class="cta-white mb-2 continue" data-cy="next-button" :disabled="!gdprCheckboxFooter">
      {{ CurrentShowroom.IsOpen ? $t('appoint-form-next-btn', 'Next') : $t('keep-me-informed', 'Keep Me Informed') }}
    </button>
  </form>
</template>

<script setup>
import { useVuelidate } from '@vuelidate/core'
import { required, minLength, between, email, requiredIf, helpers } from 'vuelidate/lib/validators'
import mockedPhoneCodes from '@/mockedData/phoneCodes'

import { useOnlineAppointments } from '~/store/bookAppointmentStore'

const { UserData, CurrentShowroom } = storeToRefs(useOnlineAppointments())
const localePath = useLocalePath()

const emit = defineEmits(['nextStep'])

const phone = helpers.regex('phone', /^$|^\d+$|^\d+$/)

const phoneCodes = computed(() => mockedPhoneCodes)

// const user = ref({
//     FirstName: null,
//     LastName: null,
//     Email: null,
//     PhoneCode: null,
//     PhoneNumber: null,
// });

const gdprCheckboxFooter = ref(null)

// Vuelidate validations
// const rules = {
// }

const rules = computed(() => {
  const localRules = {
    UserData: {
      FirstName: { required },
      LastName: { required, minLength: minLength(3) },
      Email: { required, email },
      // PhoneCode: { required },
      // PhoneCode: { required },
      // PhoneNumber: { required, phone },
    },
  }

  if (CurrentShowroom.value.IsOpen) {
    // if billing is not the same as shipping, require it
    localRules.UserData.PhoneCode = { required }
    localRules.UserData.PhoneNumber = { required, phone }
  }
  return localRules
})

const $v = useVuelidate(rules, { UserData })

const submit = () => {
  $v.value.UserData.$touch()

  if ($v.value.UserData.$invalid) {
    console.log('error')
    setTimeout(() => document.querySelector('._form-group--error :first-child ._form-control').focus(), 10)
  } else {
    // do your submit logic here
    console.log('ok')

    // this.setStep(step);
    emit('nextStep')
  }
}

// export default {
//   data() {
//     return {
//         gdprCheckboxFooter: false,
//     };
//   },

//   validations() {
//     // let isDeliveryAddress = requiredIf(() => this.UserData.DeliveryOption == 'DeliveryAddress')

//     return {
//       user: {
//         FirstName: { required },
//         LastName: { required, minLength: minLength(3) },
//         Email: { required, email },
//         PhoneCode: { required },
//         PhoneNumber: { required, phone },
//       },
//     };
//   },

//   computed: {``
//     ...mapState([
//       "user"
//     ]),

//     phoneCodes() {
//       return _77Settings.PhoneCodes;
//     },
//   },
//   methods: {

//     submit() {
//       this.$v.UserData.$touch();

//       if (this.$v.UserData.$invalid) {
//         console.log("error");
//         setTimeout(
//           () => $("._form-group--error:first ._form-control").focus(),
//           10
//         );
//       } else {
//         // do your submit logic here
//         console.log("ok");

//         // this.setStep(step);
//         this.$emit('nextStep', { userData: this.user })
//       }
//     },
//   },

//   mounted() {
//   },
// };
</script>
